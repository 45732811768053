import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  BreadcrumbItem,
  Tooltip,
} from "reactstrap";
import Loading from "../../components/Loading.js";
import Navbar from "../../components/Navbar.js";
import { HomeS } from "../../Home.js";
import { SubTitle } from "../HeaderAnnuaire.js";
import FindePage from "../FindePage.js";
import Footer from "../../components/Footer.js";
import RechercheParVille from "../RechercheParVille.js";
import CardNotaire from "../CardNotaire.js";
import { HeaderBannerThree } from "../HeaderAnnuaire2.js";
import CtaNotaire from "../CtaNotaire.js";
import Surligne from "../../img/deski/shape/line-shape-12.svg";
import axios from "axios";
import {
  ColNotaire,
  Content,
  BreadcrumbS,
  FormS,
  Pagination,
  RowFiltres,
} from "../../style/Annuaire.js";
import { EmptyData } from "./component/EmptyData.js";
import {Helmet} from "react-helmet";

function NotairesVilleLille(args) {
  const [notaires, setNotaires] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [state, setState] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [data, setData] = useState([]);
  const [dataNotaires, setDataNotaires] = useState([]);
  const [itemsPage, setItemsPage] = useState([]);

  const namePage = "Lille";

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 30;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const totalPages = Math.ceil(notaires?.length / itemsPerPage);

  const SPREADSHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const RANGE = "Haut de France";

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setItemsPage(notaires?.slice(indexOfFirstItem, indexOfLastItem));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Cette option permet d'avoir un défilement fluide
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
        );
        const rows = response.data.values;
        console.log(rows);
        setData(rows);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 1) {
      const headers = data[0];
      const rows = data.slice(1);
      const formatted = rows.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || "";
        });
        return obj;
      });

      if (formatted != null) {
        var filteredData = formatted.filter(
          (item) => item.ville === namePage.toUpperCase()
        );

        filteredData.sort((a, b) => {
          if (a.utilise_clotere === "oui" && b.utilise_clotere === "non") {
            return -1;
          }
          if (a.utilise_clotere === "non" && b.utilise_clotere === "oui") {
            return 1;
          }
          return 0;
        });

        setNotaires(filteredData);
        setDataNotaires(filteredData);

        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (notaires != null) {
      setItemsPage(notaires?.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [notaires]);

  useEffect(() => {
    if (notaires != null) {
      var newItems = notaires?.slice(indexOfFirstItem, indexOfLastItem);
      console.log(newItems);
      setItemsPage(newItems);
    }

    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    console.log(dataNotaires);
    if (state == true) {
      var filteredData = dataNotaires.filter(
        (item) => item.utilise_clotere === "oui"
      );
      console.log(filteredData);
      setNotaires(filteredData);
    } else {
      var filteredData = dataNotaires.filter(
        (item) => item.ville === namePage.toUpperCase()
      );
      console.log(filteredData);
      setNotaires(filteredData);
    }

    setCurrentPage(1);
  }, [state]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
    <Helmet>
        <title>Trouver votre notaire à {namePage}</title>
      </Helmet>
      <HomeS>
        <Navbar user={args.user} />
        <HeaderBannerThree className="grey-bg">
          <Row className="d-flex align-items-center" align="center">
            <Col md="12" align="center">
              <BreadcrumbS>
                <BreadcrumbItem>
                  <Link to="/fr/notaires">Annuaire des notaires de France</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/fr/notaires">Notaires {RANGE}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Notaire à {namePage}</BreadcrumbItem>
              </BreadcrumbS>
            </Col>
            <Col md="12" xs="12" align="center">
              <h1>
                Trouvez{" "}
                <span className="surligne">
                  {" "}
                  votre notaire
                  <img src={Surligne} alt="shape" class="cs-screen" />
                </span>{" "}
                à {namePage}
              </h1>
              <SubTitle>
                Retrouvez dans l'annuaire des notaires de {namePage}, le notaire
                idéal pour vous accompagner dans vos démarches.
                <br />
              </SubTitle>
            </Col>
          </Row>
        </HeaderBannerThree>
        <Content>
          <Container>
            <RowFiltres>
              <Col md="6" align="left">
                <h3>
                  <small>
                    {notaires.length} notaires à{" "}
                    <span className="textHighlight">{namePage}</span>
                  </small>
                </h3>
              </Col>
              <Col md="6" align="right">
                <FormS>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={state}
                      onClick={() => {
                        setState(!state);
                      }}
                    />
                    <Label check>
                      <a href="#" rel="noreferrer" id="TooltipExample">
                        Gère votre dossier en ligne
                      </a>
                    </Label>
                  </FormGroup>
                </FormS>
                <Tooltip
                  {...args}
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Ces notaires vous assurent un suivi de votre dossier en ligne
                  facilement.
                </Tooltip>
              </Col>
            </RowFiltres>

            {itemsPage.length > 0 ? (
              <ColNotaire>
                {itemsPage.map((col, i) => (
                  <>
                    <CardNotaire
                      fiche={itemsPage[i].lien_fiche}
                      key={i}
                      nom={itemsPage[i].nom}
                      adresse={itemsPage[i].adresse}
                      cp={itemsPage[i].cp}
                      ville={itemsPage[i].ville}
                      site={itemsPage[i].site}
                      use={itemsPage[i].utilise_clotere}
                      photo={itemsPage[i].photo_profil}
                    />
                  </>
                ))}
              </ColNotaire>
            ) : (
              <EmptyData />
            )}

            <Pagination>
              <button
                onClick={() => handlePrevious()}
                disabled={currentPage === 1}
              >
                &lt; Précédent
              </button>
              <span>
                Page {currentPage} sur {totalPages}
              </span>
              <button
                onClick={() => handleNext()}
                disabled={currentPage === totalPages}
              >
                Suivant &gt;
              </button>
            </Pagination>

            <RechercheParVille />
            <FindePage ville={namePage} />
          </Container>
          <CtaNotaire />
        </Content>
        <Footer />
      </HomeS>
    </>
  );
}

export default NotairesVilleLille;
