import {
    Row,
    Col
  } from "reactstrap";

export const EmptyData = () => {
    return (
        <Row>
            <Col md='12' align="center" >
            <h4 class="mt-5 mb-5">
                <span role="img">🫤</span>
                Aucun notaire disponible pour cette recherche
            </h4>
            </Col>
        </Row>
        
    );
};