import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container, Row} from "reactstrap";

import { SearchBar } from "./SearchBar";
import { Avantages } from "./Avantages";
import { RechercheParVilleS, ListeVille } from './RechercheParVille';
/** style */
import { SearchForm } from '../style/Annuaire';
/** images */
import Surligne from '../img/deski/shape/line-shape-12.svg';
import Background from "../img/back-clotere.png"

export const HeaderBannerThree = styled.div`
background-image: url(${Background});
background-position:top center;
background-size:cover;
  padding: 60px 0 0;
  position: relative;


h1 {
  font-size: 85px;
  line-height: 1.05em;
  text-align: center;
}
.sub-text {
  font-size: 28px;
  line-height: 1.39em;
  font-weight: 300;
  color: #2a2a2a;
  text-align: center;
  padding: 25px 0 45px;
}
.sing-in-call {
  font-size: 16px;
  padding-top: 25px;
  color: #2c2c2c;
  text-align: center;
}
.sing-in-call a {
  color: var(--blue-light);
  transition: all 0.25s ease-in-out;
}
.sing-in-call a:hover {
  text-decoration: underline;
}
.illustration {
  margin: 95px auto 0;
}


`;

const ContainerCcm = styled(Container)`
margin:4rem 0;
.block-style-twentyEight .icon {
  img{height: 70px;}
}
  .block-style-twentyEight h4 {
	font-weight: 500;
	font-size: 24px;
	margin: 33px 0 20px;
  }
`;

const HeaderAnnuaire2 = () => {
  const [results, setResults] = useState([]);
  return (
    <HeaderBannerThree className="hero-banner-three">
      <Container className="container">
        <Row>
          <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
            <h1>Trouvez  <span className="surligne"> votre notaire<img src={Surligne} alt="shape" class="cs-screen" /></span></h1>

          </div>
          {/* End .col */}

          <div className="col-xl-8 col-lg-9 m-auto">
            <p className="sub-text">
              L'annuaire des notaires de France.
            </p>
          </div>
          {/* End .col */}
        </Row>
        {/* End .row */}

        <SearchForm>
          <SearchBar />
        </SearchForm>
        {/* End search-filter-from */}
      </Container>
      <Container>
        <Row>
          <RechercheParVilleS>
            <Container>
              <h2 className="mb-5 mt-5"><small>Trouvez votre notaire dans les grandes villes de France</small></h2>
              <ListeVille>
                <Link to="/fr/notaires/ville/paris">Notaires à Paris</Link>
                <Link to="/fr/notaires/ville/lyon">Notaires à Lyon</Link>
                <Link to="/fr/notaires/ville/lille">Notaires à Lille</Link>
                <Link to="/fr/notaires/ville/bordeaux">Notaires à Bordeaux</Link>
                <Link to="/fr/notaires/ville/rennes">Notaires à Rennes</Link>
                <Link to="/fr/notaires/ville/montpellier">Notaires à Montpellier</Link>
                <Link to="/fr/notaires/ville/toulouse">Notaires à Toulouse</Link>
                <Link to="/fr/notaires/ville/marseille">Notaires à Marseille</Link>
                <Link to="/fr/notaires/ville/strasbourg">Notaires à Strasbourg</Link>

              </ListeVille>
            </Container>
          </RechercheParVilleS>
          

        </Row>
        <Row>
          <ContainerCcm>

              <Avantages/>
          </ContainerCcm>
        </Row>
      </Container>
      {/* /.container */}
    </HeaderBannerThree>
    // End hero-banner-three
  );
};

export default HeaderAnnuaire2;
