import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Container, Row, Col, Badge, ListGroup, ListGroupItem, BreadcrumbItem, Card, CardBody, CardTitle } from "reactstrap";
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { HomeS } from "../Home.js"
import UseClotereCard from "./UseClotereCard";
import WantUseClotereCard from "./WantUseClotereCard";
import { HeaderBannerThree } from './HeaderAnnuaire2';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import icnNotaire from '../img/icn-notaire.svg';
import { faLink, faPhone, faLocationDot,faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonPrimary } from "../style/Button";
import ArrowRight from "../img/arrow-right.png";
import axios from 'axios';
import { BreadcrumbS,Fiche,PhotoProfil } from "../style/Annuaire.js";
import {Helmet} from "react-helmet";
import { PopupButton } from '@typeform/embed-react';



const HeaderBannerThreeS = styled(HeaderBannerThree)`
padding:40px 0 0;
h1{
font-size:48px;
margin: 1.5rem 0;
}

`;

function FicheNotaire(args) {
    const [notaire, setNotaire] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    

    const { nom } = useParams();

    const SPREADSHEET_ID = '1goMuEIM_a2g9k0jivUC4t0ABw5ABLaX1QSLvJwnGlgk';
    const API_KEY = 'AIzaSyDOypSeMIO3bhhlZt9-2KZ7OKpQW1n-njg';
    const RANGE = 'France';


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
                );
                const rows = response.data.values;
                console.log("ici",rows);
                setData(rows);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
        
    }, []);


    useEffect(() => {
        if (data.length > 1) {
            const headers = data[0];
            const rows = data.slice(1);
            const formatted = rows.map(row => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index] || '';
                });
                return obj;
            });


            if (formatted != null) {
                var filteredData = formatted.filter(item => item.lien_fiche === nom);

                if (filteredData.length > 0) {
                    if (filteredData[0].id_airtable !== null) {
                        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${filteredData[0].id_airtable}`;

                        fetch(
                            URL,
                            {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                                    'content-type': 'application/x-www-form-urlencoded',
                                    "Accept": "application/json, text/plain, /"
                                },
                            })
                            .then((res) => res.json())
                            .then((res) => {
                                var notaireAirtable = res.fields;
                                setNotaire({ ...notaireAirtable, ...filteredData[0] });
                                
                            })
                            .catch((error) => console.log(error));
                    }
                }
            }
        }
    }, [data]);


    useEffect(() => {
        console.log(notaire);
        if(notaire != null){
            setLoading(false);
        }
    }, [notaire]);


    if (isLoading) {
        return <Loading />;
    } 
    return (
        <>
         <Helmet>
        <title>Maitre {notaire.nom} - notaire à {notaire.ville}</title>
      </Helmet>
            <HomeS>
                <Navbar user={args.user} />

                <HeaderBannerThreeS className="grey-bg">
                    <Row className="d-flex align-items-center" align="center">
                        <Col md="12" xs="12" align="center">
                            <BreadcrumbS>
                                <BreadcrumbItem >
                                    <Link to="/fr/notaires">
                                        Annuaire des notaires de France
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <Link to="/fr/notaires">
                                        Notaires {RANGE}
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <Link to={`/fr/notaires/ville/${notaire.ville}`}>
                                        Notaires à {notaire.ville}
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    Notaire {notaire.nom}
                                </BreadcrumbItem>
                            </BreadcrumbS>
                            {notaire.photo_profil_from_notaire ? <>
                                <PhotoProfil>
                                <img src={notaire.photo_profil_from_notaire[0].url} alt={notaire.nom} />
                                </PhotoProfil>
                            </> : <>
                            <img src={icnNotaire} alt="notaire" />
                            </>}
                            
                            <h1><small>Maître {notaire.nom}</small></h1>
                        </Col>
                    </Row>

                    <Row className="d-flex align-items-center" align="center">
                        <Col md="12" xs="12" align="center">
                            {notaire.utilise_clotere === "oui"  ? <>
                                <UseClotereCard idnotaire={notaire.airtable_id} />
                            </> : <>
                                <p>Ce notaire n'utilise pas le service de dossier en ligne Clotere</p>
                            </>}

                        </Col>
                    </Row>

                </HeaderBannerThreeS>
                <Fiche>
                    <Container>
                        <Row>
                            <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                <Card>
                                    <CardTitle>Coordonnées</CardTitle>
                                    <CardBody>
                                        <ListGroup>
                                            <ListGroupItem>
                                                <FontAwesomeIcon icon={faLocationDot} className='mr-3 rounded' />
                                                <p>{notaire.adresse} {" "} {notaire.cp}{" "} {notaire.ville}</p>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <FontAwesomeIcon icon={faPhone} className='mr-3 rounded' />
                                                {notaire.telephone ? <>
                                                    <p>{notaire.telephone}</p>
                                                </> : <>
                                                    <p className="text-muted">aucun téléphone renseigné</p>
                                                </>}
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <FontAwesomeIcon icon={faLink} className='mr-3 rounded' />
                                                {notaire.site ? <>
                                                    <a href={notaire.site} target="blank">Voir le site</a>
                                                </> : <>
                                                    <p className="text-muted">aucune information</p>
                                                </>}

                                            </ListGroupItem>

                                        </ListGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {notaire.utilise_clotere === "oui" ? <>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                    <Card>
                                        <CardTitle>Présentation</CardTitle>
                                        <CardBody>
                                            <p className='presentation'>
                                            <div
                                            dangerouslySetInnerHTML={{
                                            __html: notaire.presentation_from_notaire
                                            }}></div>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                    <Card>
                                        <CardTitle>Domaines de compétences</CardTitle>
                                        <CardBody className='p-0'>
                                            <div className='col-competences'>
                                            {notaire.domaine_competences_from_notaire.length  ? <>
                                                {notaire.domaine_competences_from_notaire.map((col, i) => (
                                                <>
                                                <Badge className='competence'>{notaire.domaine_competences_from_notaire[i]}</Badge>
                                                </>
                                            ))}
                                                </> : <>
                                                    Non renseigné
                                                </>}

                                            
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                    <Card>
                                        <CardTitle>Services</CardTitle>
                                        <CardBody>
                                            <ListGroup>
                                                <ListGroupItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                Suivi de dossier en ligne
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                Transfert de documents sécurisés
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                Echange par messagerie sécurisée
                                                </ListGroupItem>
                                            </ListGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12 mb-5' align="center">
                                <PopupButton
                                id="cDphxVXu"
                                size={100}
                                hidden={{idnotaire: notaire.airtable_id}}
                                autoClose={false}
                                style={{ border: '0' }}
                                >
                                <ButtonPrimary color="primary">Contacter ce notaire<img src={ArrowRight} alt="" /></ButtonPrimary>
                                </PopupButton>
                                    
                                </Col>
                            </Row>
                        </> : <>
                            <Row className='mt-3'>
                                <Col className='col-md-8 offset-md-2 col-xl-8 offset-xl-2 col-xs-12 col-lg-8 offset-lg-2'>
                                    <WantUseClotereCard nom={notaire.nom} prenom={notaire.prenom} />
                                </Col>
                            </Row>

                        </>}



                    </Container>

                </Fiche>
                <Footer />
            </HomeS>
        </>

    );
}

export default FicheNotaire;