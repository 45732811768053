import React from "react";
import { Card, CardTitle } from "reactstrap";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { ButtonPrimary } from "../style/Button";
import ArrowRight from "../img/arrow-right.png";


export const CardS = styled(Card)`
&.card{
background: linear-gradient(to right, #1cf36c, #4ca2cd)!important;
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:column!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:18px;
    text-align:left;
 }
 @media all and (max-width: 768px) {
    max-width:90%;
  }
}
`;


function WantUseClotereCard(args) {
    return (
        <CardS>
            <CardTitle>
                Vous êtes Maître {args.prenom} {" "} {args.nom} ?
            </CardTitle>
            <ButtonPrimary className="mt-3 mb-3" href="https://notaire.clotere.fr?utm_source=annuaire" target="blank" color="primary">Découvrez Clotere Notaire<img src={ArrowRight} alt="" /></ButtonPrimary>
            <p><small>Si vous souhaitez supprimer votre fiche notaire, écrivez nous à <a href="mailto:cecile@clotere.fr">contact@clotere.fr</a></small></p>


        </CardS>
    );
}

export default WantUseClotereCard;