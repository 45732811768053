import styled from "styled-components";
import {Row,Card,Alert} from "reactstrap";

export const TitlePage = styled.h1`
  font-family:"Manrope", "sans-serif";
  font-size:48px;
  color:${props => props.theme.colors.black};
  font-weight:400;
`;

export const TitlePageBig = styled.h1`
  font-size:32px;
  font-family:"Manrope", "sans-serif";
  color:#000;
  font-weight:400;
`;

export const TitlePageApp = styled(Row)`
margin-bottom: .5rem;
@media all and (max-width: 768px) {
  .col-md-7,.col-md-5{
    width:100%!important;
    text-align:center!important;
  }
}
`;
export const TitleSection = styled.h3`
  font-family:"Manrope", "sans-serif";
  font-size:20px;
  color:${props => props.theme.colors.black};
  font-weight:100;
  margin-bottom:20px;
`;
export const Panel = styled(Card)`
  border-radius:16px;
  padding:25px;
  border:0;
`;

export const Subtitle = styled.p`
font-family: "Manrope", sans-serif;
line-height: 44px;
font-size: 28px;
width: 90%;
color: #1D2B28;
font-weight: 300;
margin-bottom: 60px;
`;

export const AlertNotif = styled(Alert)`
display:flex;
flex-direction:row;
align-items:center;
gap:1rem;
border-radius:100px;
padding:1rem 0rem;
background-color:transparent!important;
border:0!important;
p{margin:0;}
h6{font-size:18px;margin:0;}
span{
background-color:${props => props.theme.colors.main};
    padding: 1rem 1.5rem;
    border-radius: 100px;
}
    img{
    position: absolute;
    top: -12px;
    right: 0px;
    }

`;

export const AlertCta = styled(Alert)`
&.alert-primary{
background-image: linear-gradient(to right, #03606a 0%, #49f47c 100%);
border-radius: 1.5rem;
padding:2rem;
display:flex;
flex-direction:row;
align-items:start;
gap:1rem;
border:0!important;  
color:#fff;
.bloc{
width: 70%;
display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding:3rem;
}
  .blog-img{
    flex-grow: 1!important;
    position:relative;
    text-align:right;
      img{
        width: 100%;
        max-height: 350px;
        max-width: 100%;
      }
    }

h3{font-size:32px;}
ul{
  li{
        font-size: 16px;
      line-height: 2.5;
  }
}
  a{
  padding: 1rem 2rem;
    background-color: #49f47c;
    border-radius: 100px;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }
}
  @media all and (max-width: 768px) {
  &.alert-primary{
    flex-direction:column-reverse;
    padding:0;
    .bloc{
          padding: 2rem;
          width:100%;
    }
          .blog-img{display:none;}

          ul li{
            line-height: 1.5;
            margin-bottom: 1rem;
          }
  }
 
}

`;




