import React from "react";
import { CardTitle,Container,Row,Col,CardBody,CardText } from "reactstrap";
import { CardArticle } from "../style/Blog";
/* img articles */
import img1 from "../img/blog/img-blog-2.png";
import img2 from "../img/blog/Frame 32.png";
import img3 from "../img/blog/image 7.png";



function Articles(args) {

    return (
                    <Container>
                        <Row className="d-flex align-items-start justify-content-center">
                            
                            <Col md="4" xs="12">
                            <CardArticle>
                                    <img className="mainimg" src={img1} alt="clotere" />

                                    <CardBody>
                                        <CardTitle>Comment choisir son notaire pour son achat immobilier ? </CardTitle>
                                        <CardText className="d-none d-sm-block">
                                        Acquérir un bien immobilier requiert toujours l’intervention d’un notaire, au moins pour réaliser l’acte de vente. Comment faire le choix d’un notaire pour un achat immobilier ? Découvrez nos conseils.
                                        </CardText>
                                        <a href="https://clotere.webflow.io/post/comment-choisir-son-notaire-pour-son-achat-immobilier" target="blank">Lire l'article</a>
                                        <div className="EndBloc">
                                        <div className="card-blog-author">
                                            <img alt="trouver-votre-notaire" height="50" width="50" auto="best" class="vesta-card-blog--expert-pic" src="https://cdn.prod.website-files.com/667692023d1ef88b51e3c4cf/667bfa137f95571961a9bc62_icn-author-clotere.png" />
                                            <div class="content-author">
                                                <p><b>Rédigé par Clotere</b></p>
                                                <p class="read-time">Lecture : 7 min</p>
                                            </div>
                                        </div>
                                        
                                        </div>
                                    </CardBody>
                                </CardArticle>
                            </Col>
                            <Col md="4" xs="12">
                                <CardArticle>
                                    <img alt="trouver un notaire qualifié" className="mainimg" src={img2} />

                                    <CardBody>
                                        <CardTitle>Les clauses suspensives, finalement qu'est-ce que c'est ?</CardTitle>
                                        <CardText className="d-none d-sm-block">Si vous achetez un bien immobilier vous entendrez forcément parlé des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article.</CardText>
                                        <a href="https://clotere.webflow.io/post/les-clauses-suspensives-finalement-quest-ce-que-cest" target="blank">Lire l'article</a>
                                        <div className="EndBloc">
                                        <div className="card-blog-author">
                                            <img alt="auteur" height="50" width="50" auto="best" class="vesta-card-blog--expert-pic" src="https://cdn.prod.website-files.com/667692023d1ef88b51e3c4cf/667bfa137f95571961a9bc62_icn-author-clotere.png" />
                                            <div class="content-author">
                                                <p><b>Rédigé par Clotere</b></p>
                                                <p class="read-time">Lecture : 5 min</p>
                                            </div>
                                        </div>
                                        
                                        </div>
                                    </CardBody>
                                </CardArticle>
                            </Col>
                            
                            <Col md="4" xs="12">
                            <CardArticle>
                                    <img  alt="trouver un notaire pour votre achat immobilier" className="mainimg" src={img3}/>

                                    <CardBody>
                                        <CardTitle>Quels sont les documents de vente chez le notaire pour un achat immobilier ? </CardTitle>
                                        <CardText className="d-none d-sm-block">
                                        Durant la négociation, vous aurez à signer plusieurs documents actant la vente de ce bien immobilier. Quels sont ces documents ? À quoi servent-ils ? On vous en dit plus.
                                        </CardText>
                                        <a href="https://clotere.webflow.io/post/quels-sont-les-documents-de-vente-chez-le-notaire-pour-un-achat-immobilier" target="blank">Lire l'article</a>
                                        <div className="EndBloc">
                                        <div className="card-blog-author">
                                            <img alt="notaire" height="50" width="50" auto="best" class="vesta-card-blog--expert-pic" src="https://cdn.prod.website-files.com/667692023d1ef88b51e3c4cf/667bfa137f95571961a9bc62_icn-author-clotere.png" />
                                            <div class="content-author">
                                                <p><b>Rédigé par Clotere</b></p>
                                                <p class="read-time">Lecture : 5 min</p>
                                            </div>
                                        </div>
                                       
                                        </div>
                                    </CardBody>
                                </CardArticle>
                            </Col>
                        </Row>
                    </Container>
    );
}

export default Articles;