import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input, Alert } from "reactstrap";
import { Panel } from "../style/Layout";
import { Link } from 'react-router-dom';
import { HomeS } from "./ChoisirNotaire";
import { useParams } from "react-router";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonPrimarySmall, ButtonPrimary } from "../style/Button";
function RelireDocument(args) {
    const { id_event } = useParams();
    //console.log("event", args);
    const [infoEvent, setInfoEvent] = useState(null);
    const [validation, setValidation] = useState(false);
    const [erreur, setErreur] = useState(false);


    //get info event
    const getInfoEvent = async () => {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event/${id_event}`;

        fetch(
            URL,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    'content-type': 'application/x-www-form-urlencoded',
                    "Accept": "application/json, text/plain, /"
                },
            })
            .then((res) => res.json())
            .then((res) => {
                //console.log("info event", res.fields.document_from_document[0].url);
                setInfoEvent(res.fields);
                return
            })
            .catch((error) => console.log(error));

    };

    //use effect event
    useEffect(() => {
        getInfoEvent();
    }, []);

    const sendToZapier = async () => {
        const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/22qgvsi/"; // 👈 Specify your Zapier Webhook URL here
        try {
          const response = await fetch(zapierURL, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({id_event})
          });
          const resp = await response.json();
          console.log(resp);
        } catch (e) {
          console.log(e);
        }
      };

    //signalement erreur doc
    const handleSubmit = (event) => {
        event.preventDefault();
        const erreurI = event.target.erreurInput.value;
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event/${id_event}`;
        fetch(
            URL,
            {
                method: "PATCH",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    "Accept": "application/json",
                    'content-type': "application/json"
                },
                body: JSON.stringify({
                    "fields": {
                        "erreurs":`message de l'erreur :  ${erreurI} `,
                        "etat":"erreurs signalées"
                    }
                })
            })
            .then((res) => res.json())
            .then((res) => 
                setErreur(true),
                sendToZapier(),
                setTimeout(() => {
                    setErreur(false);
                }, 3000)
        );
            

    }


    const validationDocument = async () => {
        var date = new Date();
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/event/${id_event}`;
        fetch(
            URL,
            {
                method: "PATCH",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    "Accept": "application/json",
                    'content-type': "application/json"
                },
                body: JSON.stringify({
                    "fields": {
                        "validation":`validé par le vendeur ou acheteur le ${date}  `,
                    }
                })
            })
            .then((res) => res.json())
            .then(setValidation(true))
    }


    if (infoEvent !== null) {
        return (
            <>
                <HomeS>
                    <Container>
                        <Row>
                            <Col md='12'>
                                <Link to="/app/dashboard" className="back d-flex flex-row">
                                    <p className="m-0"><FontAwesomeIcon icon={faArrowLeftLong} className='mr-3' /> {"        "} Retour</p>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <h3 className="mt-3">Relisez votre document de vente</h3>
                                <p>Si vous constatez des erreurs dans le document n'hésitez pas à nous le signaler, nous avertiront votre notaire.</p>
                            </Col>
                            <Row>
                                <Col md="8">
                                    <iframe title="document" src={infoEvent.document_from_document[0].url} width={"100%"} height={"600px"}></iframe>
                                </Col>
                                <Col md="4">
                                    <Panel className="text-center">
                                        {validation === true && <>
                                            <Alert color="primary">👍 Document validé. Nous transmettons l'information à votre notaire.</Alert>
                                        </>}
                                        <ButtonPrimary color="primary" onClick={validationDocument}>Valider le document</ButtonPrimary>
                                        <p>Cela veut dire que vous ne constater pas d'erreur sur le document.</p>
                                        <br />
                                        <h6>Vous constatez des erreurs ?</h6>
                                        <Form onSubmit={handleSubmit}>
                                            {erreur === true && <>
                                                <Alert color="danger">C'est noté, nous transmettons l'erreur à votre notaire.</Alert>
                                            </>}
                                            <FormGroup>
                                                <Input name="erreurInput" type="textarea" rows="12" placeholder="Dites nous ce qui ne va pas sur le document"></Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <ButtonPrimarySmall>Signaler une erreur</ButtonPrimarySmall>
                                            </FormGroup>
                                        </Form>
                                    </Panel>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </HomeS>

            </>
        );
    }
    return (<><HomeS>Chargement...</HomeS></>)
}

export default RelireDocument;