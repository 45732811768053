import styled from "styled-components";


const PaginationStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    background-color: #f9f9f9;

    &:hover {
      background-color: #f1f1f1;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #ddd;
    }
  }

  span {
    margin: 0 10px;
  }
`;

export const PaginationComponent = ({ currentPage, totalPages, onPrevious, onNext }) => {




    return (
        <PaginationStyle>
            <button onClick={onPrevious} disabled={currentPage === 1}>
                &lt; Précédent
            </button>
            <span>
                Page {currentPage > totalPages ? totalPages : currentPage} sur {totalPages}
            </span>
            <button onClick={onNext} disabled={currentPage === totalPages}>
                Suivant &gt;
            </button>
        </PaginationStyle>
    );
};