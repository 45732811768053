import React, { useState } from "react";
import { ListGroup, ListGroupItem, Offcanvas, Badge, OffcanvasHeader, OffcanvasBody, Col, Alert } from "reactstrap";
import { Link } from 'react-router-dom';
import { faLink, faPhone, faLocationDot, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icnNotaire from '../img/icn-notaire.svg';
import { ButtonPrimarySmall, ButtonPrimary } from "../style/Button";
import { CardNotaires, BadgeUse, PhotoProfil } from "../style/Annuaire";
import ContentChoisirNotaire from "../components/ContentChoisirNotaire";
import ContentChoisirNotaireDone from "../components/ContentChoisirNotaireDone";

function CardNotaire(args) {
    const [canvas, setCanvas] = useState(false);
    const [notaireChoisiDone, setNotaireChoisiDone] = useState(false);
    const toggleChoisir = () => setCanvas(!canvas);
    //console.log("args", args)

    function ChoisirNotaire() {
        const idNotaire = args.idNotaire;
        const URLTransaction = `https://api.airtable.com/v0/appD48APNaGA4GN0B/transaction/${args.idTransaction}`;
        fetch(
            URLTransaction,
            {
                method: "PATCH",
                headers: {
                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                    "Accept": "application/json",
                    'content-type': "application/json"
                },
                body: JSON.stringify({
                    "fields": {
                        "statut_marketplace_notaire": "en cours d'attribution",
                        "notaire": idNotaire,
                    }
                })
            })
            .then((res) => res.json())
            .then((res) => setNotaireChoisiDone(true))

            .catch((error) => console.log(error));

    }
    return (
        <>
            <CardNotaires className={(args.use === "oui" ? 'reco' : '')}>
                {args.photo ? <PhotoProfil><img src={args.photo} alt={args.prenom + " " + args.nom} /></PhotoProfil> : <img src={icnNotaire} alt="notaire" />}

                <div>
                    {args.type_reco === "recommandation agent" &&
                        <>
                            <ListGroupItem className='p-0'>
                                <p><FontAwesomeIcon icon={faStar} className='mr-3 rounded' />
                                    <b>Votre agent vous recommande ce notaire</b></p>
                            </ListGroupItem>
                        </>
                    }
                    <div className="head">
                        {args.fiche && 
                        <>
                            {args.fiche === "non" ?
                            <h4> Maitre {args.prenom} {" "}{args.nom}</h4>
                            :
                            <Link to={`/notaire/${args.fiche}`}>
                                <h4> Maitre {args.prenom} {" "}{args.nom}</h4>
                            </Link>
                        }
                        </>
                        }
                        {/** composant pour la page notaire app */}
                        {args.lien_fiche &&
                        <>
                            <Link to={args.lien_fiche} target="_blank">
                            <h4> Maitre {args.prenom} {" "}{args.nom}</h4>
                            </Link>
                        </>
                        }
                    </div>
                    
                    {args.use === "oui" ? <BadgeUse className="use">Notaire recommandé</BadgeUse> : <Badge>Notaire</Badge>}



                    <ListGroup horizontal>

                        <ListGroupItem className='p-0'>
                            <FontAwesomeIcon icon={faLocationDot} className='mr-3 rounded' />
                            <p>{args.adresse} {" "} {args.cp}{" "} {args.ville}</p>
                        </ListGroupItem>

                    

                        {args.site &&
                            <>
                                <ListGroupItem className='p-0'>
                                    <FontAwesomeIcon icon={faLink} className='mr-3 rounded' />
                                    <a href={`${args.site}?utm_source=clotere`} target="blank">Consulter le site internet</a>
                                </ListGroupItem>
                            </>
                        }
                        {args.use === "oui" &&
                            <>
                                <>
                                    <ListGroupItem className='p-0'>
                                        {args.reco === "oui" ?
                                            <Link onClick={toggleChoisir}>
                                                <ButtonPrimarySmall color="primary">Choisir ce notaire</ButtonPrimarySmall>
                                            </Link>
                                            :
                                            <Link to={`/notaire/${args.fiche}`}>
                                                <ButtonPrimarySmall color="primary">Contacter ce notaire</ButtonPrimarySmall>
                                            </Link>
                                        }

                                    </ListGroupItem>
                                </>
                            </>
                        }
                    </ListGroup>
                </div>
            </CardNotaires>
            <Offcanvas
                isOpen={canvas}
                toggle={toggleChoisir}
                {...args}
                direction="end"
                scrollable
            >
                {notaireChoisiDone === false ?
                    <>
                        <OffcanvasHeader toggle={toggleChoisir} className="m-0">
                            Vous êtes sur le point de choisir ce notaire
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <Col md='12' align="center" className="mt-2">
                                <Alert color="light">
                                    Ce notaire va recevoir votre dossier et gérer votre transaction immobilière. Si ce notaire utilise Clotere, vous pourrez échanger vos documents et suivre votre transaction depuis votre espace sécurisé Clotere.
                                </Alert>
                                <ButtonPrimary color="primary" onClick={ChoisirNotaire} >Choisir ce notaire</ButtonPrimary>
                                <p><br />
                                    <small>En choisissant de confier votre affaire à ce notaire,<br /> vous acceptez <a href="https://clotere.fr/cgu" target="blank">les conditions générales d’utilisation</a> de Clotere. </small>
                                </p>
                            </Col>
                            <ContentChoisirNotaire />

                        </OffcanvasBody>
                    </>
                    :
                    <>
                        <OffcanvasHeader className="m-0">
                            <span role="img">🥳</span> Félicitations vous avez choisi votre notaire !
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <Col md='12' align="center" className="mt-2">
                                <ContentChoisirNotaireDone />
                            </Col>
                            <Col md='12' align="center" className="mt-2">
                                <Link to="/app/dashboard"><ButtonPrimarySmall color="primary">Ok, j'ai compris</ButtonPrimarySmall></Link>
                            </Col>

                        </OffcanvasBody>
                    </>
                }
            </Offcanvas>
        </>


    );
}

export default CardNotaire;