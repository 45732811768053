import React from "react";
/** composants **/
import { Card, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ListGroupAvantage = styled(ListGroup)`
p{
    margin:0;
    text-align:left;
    .light{color:#84847C;}
}
.icon{
    border-radius: 100px;
    padding: 0.5rem;
    
    width: 40px;
    height: 40px;
    text-align: center;
    background-color:${props => props.theme.colors.main};
    margin-right:1rem;
}
.bg-primary{
    background-color:#CCE1DD!important;
    color:${props => props.theme.colors.greenDark}!important;
}

.list-group-item{
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #ddd!important;
    border: 0;
    background-color: transparent;
}
`;


export const CardS = styled(Card)`
&.card{
background: linear-gradient(to right, #1cf36c, #4ca2cd)!important;
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:column!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:18px;
    text-align:left;
 }
 @media all and (max-width: 768px) {
    max-width:90%;
  }
}
`;


function ContentChoisirNotaireDone(args) {
    return (
        <Row>
                        <Col md="12" align="center"  className="mt-3">
                        
                            <h4><span role="img">👋</span> Prochaines étapes</h4>
                            <ListGroupAvantage className="mt-3">
                                <ListGroupItem className="d-flex align-items-center">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faInfoCircle} className='mr-3' />
                                    </div>
                                    <p>
                                        <b>Nous allons contacter le notaire pour lui transmettre votre dossier</b><br />
                                        <span className="light">
                                            Nous allons avertir ce notaire que vous l'avez choisi
                                        </span>
                                    </p>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex align-items-center">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faEnvelope} className='mr-3' />
                                    </div>
                                    <p>
                                        <b>Il prendra contact avec vous</b><br />
                                        <span className="light">Dès qu'il prendra l'affaire en charge vous serez avertie par email</span>
                                    </p>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex align-items-center">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faEnvelope} className='mr-3' />
                                    </div>
                                    <p>
                                        <b>Il vérifiera les documents</b><br />
                                        <span className="light">Il commencera à vérifier la complétude et l'exactitude des documents</span>
                                    </p>
                                </ListGroupItem>
                                </ListGroupAvantage>
                        </Col>
                    </Row>
    );
}

export default ContentChoisirNotaireDone;