import React from "react";
import { Card, CardTitle } from "reactstrap";
import styled from "styled-components";
import { ButtonPrimary } from "../style/Button";
import ArrowRight from "../img/arrow-right.png";
import { PopupButton } from '@typeform/embed-react';

export const CardS = styled(Card)`
max-width:60%;
background: linear-gradient(to right, #1cf36c, #4ca2cd)!important;
border-radius: 20px;
border: 0;
background-color:transparent;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:row!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:18px;
    text-align:left;
 }
 @media all and (max-width: 768px) {
    max-width:90%;
  }
`;


function UseClotereCard(args) {
    //console.log("args fiche",args.idnotaire)
    return (
        <CardS>
            <CardTitle>
                <span role="img"></span>Ce notaire vous propose le suivi de dossier en ligne
            </CardTitle>
            <PopupButton
                id="cDphxVXu"
                size={100}
                hidden={{ idnotaire: args.idnotaire }}
                autoClose={false}
                style={{ border: '0',background:"transparent" }}
            >
                <ButtonPrimary color="primary">Contacter ce notaire<img src={ArrowRight} alt="" /></ButtonPrimary>
            </PopupButton>


        </CardS>
    );
}

export default UseClotereCard;