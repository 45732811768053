import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
/** style  */
import {ContainerListeArticle, RowArticle} from '../style/Blog';
/* img articles */
import Surligne from '../img/deski/shape/line-shape-12.svg';

function ArticlesSimilaires(args) {

  return (
    <>
      <ContainerListeArticle>
        <Row>
          <Col md="12" align="center" className="mb-5">
            <h2>Votre <span className="surligne"><img src={Surligne} alt="shape" class="cs-screen" /> notaire en clair</span></h2>
            <p>Retrouvez dans notre blog tous les conseils pour votre passage chez le notaire</p>
          </Col>
        </Row>
        <RowArticle>
          <Col
            className="col-lg-4 col-sm-6 col-article"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="0"
          >
            <article className="post-meta mt-30">
              <figure className="post-img m0">
                <Link to="https://clotere.webflow.io/post/les-frais-de-notaire-dans-limmobilier" target="blank" className="w-100 d-block">
                  <img
                    src="https://cdn.prod.website-files.com/667abe8623fe3d0e75289a62/668d8c483cf92186b04e6edf_Frame-35.webp"
                    alt="blog"
                    className="w-100 tran4s"
                  />
                </Link>
              </figure>
              <div className="post-data">
                <h3 className="blog-title">
                  <Link to="https://clotere.webflow.io/post/les-frais-de-notaire-dans-limmobilier" target="blank" >Comprendre les frais de notaire dans l'immobilier </Link>
                </h3>
                <p>
                  Allant jusqu'à 6% du prix d'achat de votre bien, les frais de notaire représente un poste de dépense important. Bien anticipé ce coût est important, on vous explique tout sur ces frais de notaire.
                </p>
                <Link to="https://clotere.webflow.io/post/les-frais-de-notaire-dans-limmobilier" target="blank"  className="read-btn tran3s">
                  En savoir plus
                </Link>
              </div>
              {/* <!-- /.post-data --> */}
            </article>
          </Col>
          <Col
            className="col-lg-4 col-sm-6  col-article"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <article className="post-meta mt-30">
              <figure className="post-img m0">
                <Link to="https://clotere.webflow.io/post/les-clauses-suspensives-finalement-quest-ce-que-cest" target="blank" className="w-100 d-block">
                  <img
                    src="https://cdn.prod.website-files.com/667abe8623fe3d0e75289a62/668d463e4cb22c2895a3e58a_Frame-33.webp"
                    alt="blog"
                    className="w-100 tran4s"
                  />
                </Link>
              </figure>
              <div className="post-data">
                <h3 className="blog-title">
                  <Link to="https://clotere.webflow.io/post/les-clauses-suspensives-finalement-quest-ce-que-cest" target="blank">Les clauses suspensives, finalement qu'est-ce que c'est ?</Link>
                </h3>
                <p>Si vous achetez un bien immobilier vous entendrez forcément parlé des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article. </p>
                <Link to="https://clotere.webflow.io/post/les-clauses-suspensives-finalement-quest-ce-que-cest" target="blank" className="read-btn tran3s">
                  En savoir plus
                </Link>
              </div>
              {/* <!-- /.post-data --> */}
            </article>
          </Col>
          <Col
            className="col-lg-4 col-sm-6  col-article"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="0"
          >
            <article className="post-meta mt-30">
              <figure className="post-img m0">
                <Link to="https://clotere.webflow.io/post/comment-choisir-son-notaire-pour-son-achat-immobilier" target="blank" className="w-100 d-block">
                  <img
                    alt="trouver un notaire pour votre achat immobilier"
                    src="https://cdn.prod.website-files.com/667abe8623fe3d0e75289a62/668befc3325f928487de5d9d_Frame-18.webp"
                    className="w-100 tran4s"
                  />
                </Link>
              </figure>
              <div className="post-data">
                <h3 className="blog-title">
                  <Link to="https://clotere.webflow.io/post/comment-choisir-son-notaire-pour-son-achat-immobilier" target="blank">Comment choisir son notaire pour son achat immobilier ?</Link>
                </h3>
                <p>
                Acquérir un bien immobilier requiert toujours l’intervention d’un notaire, au moins pour réaliser l’acte de vente. Comment faire le choix d’un notaire pour un achat immobilier ? Découvrez nos conseils.
                </p>
                <Link to="https://clotere.webflow.io/post/comment-choisir-son-notaire-pour-son-achat-immobilier" target="blank" className="read-btn tran3s">
                  En savoir plus
                </Link>
              </div>
              {/* <!-- /.post-data --> */}
            </article>
          </Col>
          
        </RowArticle>
      </ContainerListeArticle>
    </>
  );
}

export default ArticlesSimilaires;