import React from "react";
/** composants **/
import { Card, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ListGroupAvantage = styled(ListGroup)`
p{
    margin:0;
    text-align:left;
    .light{color:#84847C;}
}
.icon{
    border-radius: 100px;
    padding: 0.5rem;
    
    width: 40px;
    height: 40px;
    text-align: center;
    background-color:${props => props.theme.colors.main};
    margin-right:1rem;
}
.bg-primary{
    background-color:#CCE1DD!important;
    color:${props => props.theme.colors.greenDark}!important;
}

.list-group-item{
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #ddd!important;
    border: 0;
    background-color: transparent;
}
`;


export const CardS = styled(Card)`
&.card{
background: linear-gradient(to right, #1cf36c, #4ca2cd)!important;
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:column!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:18px;
    text-align:left;
 }
 @media all and (max-width: 768px) {
    max-width:90%;
  }
}
`;


function ContentChoisirNotaire(args) {
    return (
        <Row>
                        <Col md="12" align="center"  className="mt-3">
                        
                            <h4><span role="img">👋</span> Quelques conseils pour bien choisir</h4>
                            <ListGroupAvantage className="mt-3">
                                <ListGroupItem className="d-flex align-items-center">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faInfoCircle} className='mr-3' />
                                    </div>
                                    <p>
                                        <b>Vérifiez l’adresse de l’étude</b><br />
                                        <span className="light">
                                        Il y aura des déplacements à prévoir à l’étude du notaire. Sauf si vous décidez de faire une dérogation. L’adresse de l’étude est donc un point important à vérifier
                                        </span>
                                    </p>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex align-items-center">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faEnvelope} className='mr-3' />
                                    </div>
                                    <p>
                                        <b>N’hésitez pas à contacter votre conseiller ou votre agent immo</b><br />
                                        <span className="light">Si vous avez des questions avant de faire votre choix, vous pouvez contacter votre conseiller.</span>
                                    </p>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex align-items-center">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faEnvelope} className='mr-3' />
                                    </div>
                                    <p>
                                        <b>Et si ce notaire ne me convient plus ?</b><br />
                                        <span className="light">
                                        Vous pouvez changer de notaire si il ne vous convient pas.
                                    <br />
                                    Sachez cependant que les notaires Clotere, s'engagent à être disponibles, réactifs et pédagogues pour vous accompagner de la meilleure maniere.
                                    <br />
                                    <small>Si vous n'êtes pas satisfait de l'avancement de votre dossier, contactez-nous pour nous en faire part et nous trouverons une solution.</small>
                                            </span>
                                    </p>
                                </ListGroupItem>
                                </ListGroupAvantage>
                        </Col>
                    </Row>
    );
}

export default ContentChoisirNotaire;