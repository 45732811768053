import styled from "styled-components";
import {
  Form,
  Col,
  Breadcrumb,
  Row,
  Card,
  Badge
} from "reactstrap";

export const SearchForm = styled.div `
form {
  max-width: 600px;
  margin: 0 auto;
  height: 78px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 30px 50px rgba(7, 21, 36, 0.06);
  display:flex;
  align-items:center;
}
input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 260px 0 35px;
  font-size: 16px;
  border-radius: 5px;
  margin-right:1rem;
}
button {
  right: 7px;
  bottom: 7px;
  top: 7px;
  background: #111111;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
  @media all and (max-width: 768px) {
  form {
    max-width: 100%;
    height: auto;
    flex-direction:column;
    input{margin-bottom:.5rem;text-align:center;}
    .btn-primary{width:100%}
  }
  
  }
`;

export const FormSearch = styled(Form)
`
background-color: #cee5d6;
padding: .5rem;
border-radius: 1rem;
display:flex;
flex-direction:row;
.selectville{
width:100%;
    background-color: white !important;
    border-bottom: 0 !important;
    min-width: 400px;
    position:relative;
    .css-13cymwt-control{
    height: 52px;
    margin-right: 1rem;
    border: 0;
    }

  }
`;

/** listing des notaires */
export const ColNotaire = styled(Col)
`
display:flex;
flex-direction:column;
gap:1.5rem;
flex-wrap:wrap;
flex-direction:row;
justify-content:center;
@media all and (max-width: 768px) {
  padding-right:.5rem;
  padding-left:.5rem;
  
  }
`;
export const Content = styled.div `
background-color:transparent;
`;
export const BreadcrumbS = styled(Breadcrumb)
`
justify-content:center;
display:flex;
.breadcrumb-item{
font-size:14px;
    a{
    
    color:${props => props.theme.colors.mainDark};
    }
    &.active{color:${props => props.theme.colors.main};}
}
     @media all and (max-width: 768px) {display:none;}
`;
export const FormS = styled(Form)
`
    a{
    color: black;
    text-decoration: none;
    }
    .form-check-input:checked{
    background-color:${props => props.theme.colors.main}
    border-color:${props => props.theme.colors.main}
    }
`;

export const Pagination = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border: 0;
    color:white;
    background-color: ${props => props.theme.colors.main};
    border-radius:8px;

    &:hover {
      font-weigth:bold;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
      border:0;
      color:#ddd;
    }
  }

  span {
    margin: 0 10px;
  }
`;

export const RowFiltres = styled(Row)
`
    padding: 1rem 4rem;
    display: flex;
    align-items: center;
form{
font-size:22px;
.form-check{
    display: flex;
    justify-content: end;
    gap:8px; 
    align-items:center;
    }
}
    h3 small{font-size:22px;}
    @media all and (max-width: 768px) {
    padding: 1rem;
    h3 small,form{font-size:18px;}
    .col-md-6{width:100%!important;text-align:center!important;}
    form .form-check{justify-content: center;}
`;

/**fiche des notaires */
export const Fiche = styled.div `
    
    margin-top:2rem;
    .card{
        padding:2rem;
        box-shadow:0 1px 20px 0 rgb(234 244 243);
        .card-title{
            font-size: 28px;
        }
        ul.list-group{
            border:0;
            li.list-group-item{
            border:0;
            padding-left:0;
            display:flex;
            flex-direction:row;
            align-items:center;
            gap:.5rem;
            p{margin:0;}
            svg{color:#1cf36c}
            }
        }
    }
        .col-competences{
            display:flex;
            flex-direction:row;
            gap:.8rem;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    .competence{
    border-radius: 1rem;
    padding: 1rem;
    background-color: #eaf4f3 !important;
    color: #1cf36c;
    font-size: 1rem;
    font-weight: 500;
    }
    img.photo_profil{

    }
`;
export const PhotoProfil = styled.div `
width:120px;
height: 120px;
overflow:hidden;
border-radius:100px;
img{
min-width: 100%;
    width: auto;
    height: auto;
    min-height: 100%;
    max-width: 110%;
    max-height: 110%;
}

`;

export const CardNotaires = styled(Card)
`
&.reco{box-shadow: rgb(28 243 108 / 25%) 0px 4px 12px}

padding: 0;
border: 0;
border-radius: 16px;
padding: 1.5rem;
position: relative;
box-shadow: 0 1px 20px 0 #1df36c12;
display: flex !important;
flex-direction: row !important;
gap: 2rem;
align-items: center !important;
width: 45%;
 .btn-primary{padding:10px!important;}
.badge {
    margin-top: .5rem;
    background-color: white !important;
    border: 1px solid ${props => props.theme.colors.mainDark};
    color: ${props => props.theme.colors.mainDark};
}

.head {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: start;

    a {
        color: black !important;
        text-decoration: none;
        transition: all 1s ease-out;

        h4 {
            font-size: 1.3rem;
            font-weight: 500;
            margin: 0;
        }

        &:hover {
            h4 {
                transform: scale(1.01);
            }
        }
    }

    p {
        font-size: 14px;
        opacity: .7;
        margin: 0;
        text-align:left!important;
    }
}

img {
    width: 100px;
}

.list-group {
    border: 0;
    margin: 0;
    flex-direction: column;
    gap: .5rem;
    margin-top: 1.5rem;
    text-align: center;

    @media all and (max-width: 768px) {
        flex-direction: column;
        margin-top: 1rem;
        text-align: left;
    }

    p {
        margin: 0;
        text-align:left!important;
    }

    .list-group-item {
        gap: 18px;
        border: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.8rem;
        justify-content: start;

        svg {
            color: ${props => props.theme.colors.greenDark};
        }
            a{
            color: inherit;
            text-decoration: none;
        }
    }
}
@media all and (max-width: 768px) {
  width:100%;
  img{width:75px;}
}
`;
export const BadgeUse = styled(Badge)
`
&.use{
background:linear-gradient(to right, #1cf36c, #4ca2cd)!important;
;
border:0;
}
`;
export const UseComponentCard = styled.div `
display:flex;
flex-direction:row;
gap:.5rem;
align-items:center;
justify-content:center;
position:absolute;
top:.5rem;
right:.5rem;
color:${props => props.theme.colors.mainDark};

font-size:16px;
padding:.3rem .8rem;
border-radius:10px;
p{margin:0;text-align:left!important;}
@media all and (max-width: 768px) {display:none;}
`;